// API Key is a dummy, it's injected on origin request
let redirectSignIn = `https://${process.env.GATSBY_PUBLIC_DOMAIN_NAME}/search`;
let redirectSignOut = `https://${process.env.GATSBY_PUBLIC_DOMAIN_NAME}/login`;
let graphQLEndpoint = process.env.GATSBY_AWS_APPSYNC_GRAPHQL_ENDPOINT;


// testing purposes will be removed later
console.log(
  " NODE env is ",
  process.env.NODE_ENV,
  "and ",
  process.env.GATSBY_PUBLIC_DOMAIN_NAME
);
console.log("GATSBY ENV ", process.env.GATSBY_ACTIVE_ENV);
module.exports = {
  Auth: {
    region: process.env.GATSBY_AWS_REGION,
    userPoolId: process.env.GATSBY_AWS_COGNITO_USER_POOL_ID,
    userPoolWebClientId: process.env.GATSBY_AWS_COGNITO_CLIENT_ID,
    oauth: {
      domain: process.env.GATSBY_AWS_COGNITO_DOMAIN,
      scope: [
        "phone",
        "email",
        "profile",
        "openid",
        "aws.cognito.signin.user.admin",
      ],
      redirectSignIn: redirectSignIn,
      redirectSignOut: redirectSignOut,
      responseType: "code",
    },
  },
  aws_project_region: process.env.GATSBY_AWS_REGION,
  aws_appsync_region: process.env.GATSBY_AWS_REGION,
  aws_appsync_graphqlEndpoint: graphQLEndpoint,
  aws_appsync_authenticationType: process.env.GATSBY_APPSYNC_AUTH_TYPE,
  aws_appsync_apiKey: process.env.GATSBY_AWS_APPSYNC_GRAPHQL_API_KEY,
};
